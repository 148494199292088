<template>
    <div class="pdf-container">
        <canvas id="canvas1" width="250" height="250" v-show="false"></canvas>
        <canvas id="canvas2" width="250" height="250" v-show="false"></canvas>
        <el-button size="small" style="width: 80px;margin-bottom: 20px;justify-self: right;" @click="prewviewDetail">导出</el-button>
        <div id="pdfDom" class="html" v-html="htmlData"/>
    </div>
</template>
<script>
import { contractInfo } from '@/api/util.js'
import {
  infoBill,
} from "@/api/bill.js";
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
import { Loading } from 'element-ui';
import { createSealWithNameAndTime, createNameWithTime } from '../../utils/canvas_help.js'
export default {
    mounted() {
        this.companyName = this.$route.query.name
        this.getHtml(this.$route.query.data)
        this.loadingInstance = Loading.service({ fullscreen: true });
    },
    data() {
        return {
            htmlData: '',
            companyName: '',
            loadingInstance: {},
        }
    },
    methods: {
        async getHtml(url) {
            var res = await contractInfo({
                url: url
            })
            this.billInfo(res)
            // this.htmlData = res
        },
        async getInfo(bill_no, res) {
            const param = {
                bill_no: bill_no
            }
            const {code, data} = await infoBill(param)
            if (code == 200) {
                this.dealHtml(data.billInfo, res)
            }
            this.loadingInstance.close()
        },
        dealHtml(info, res) {
            var content = res
            createSealWithNameAndTime('canvas1', this.companyName, this.companyName, info.create_time.substring(0,10))
            createNameWithTime('canvas2', info.real_name, info.create_time.substring(0,10))
            // var html = ''
            if (content.indexOf("α") != -1) {
                content = content.replace("α", this.companyName)
            }
            if (content.indexOf("β") != -1) {
                content = content.replace("β", info.real_name)
            }
            if (content.indexOf("γ") != -1) {
                content = content.replace("γ", info.id_card_no)
            }
            if (content.indexOf("δ") != -1) {
                content = content.replace("δ", info.product_name)
            }
            if (content.indexOf("ε") != -1) {
                content = content.replace("ε", Math.floor(info.reality_repayment_principal))
            }
            if (content.indexOf("ε") != -1) {
                content = content.replace("ε", Math.floor(info.reality_repayment_principal))
            }
            if (content.indexOf("η") != -1) {
                let url = document.getElementById("canvas1").toDataURL("image/png")
                let img = `<image style='width=200px;height=200px;padding-top=250px' src='${url}'></image>`
                content = content.replace("η", img)
            }
            if (content.indexOf("θ") != -1) {
                let url = document.getElementById("canvas2").toDataURL("image/png")
                let img = `<image style='width=150px;height=150px;padding-top=250px' src='${url}'></image>`
                content = content.replace("θ", img)
            }
            
            this.htmlData = content
        },
        billInfo(res) {
            this.getInfo(this.$route.query.bill_id, res)
        },
        prewviewDetail() {
            html2Canvas(document.querySelector('#pdfDom'), {
                allowTaint: true
            }).then(function (canvas) {
                let contentWidth = canvas.width
                let contentHeight = canvas.height
                let pageHeight = contentWidth / 592.28 * 860
                let leftHeight = contentHeight
                let position = 0
                let imgWidth = 595.28
                let imgHeight = 592.28 / contentWidth * contentHeight
                let pageData = canvas.toDataURL('image/jpeg', 1.0)
                let PDF = new JsPDF('', 'pt', 'a4')
                if (leftHeight < pageHeight) {
                    PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
                } else {
                    while (leftHeight > 0) {
                        PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                        leftHeight -= pageHeight
                        position -= 841.89
                        if (leftHeight > 0) {
                            PDF.addPage()
                        }
                    }
                }
                PDF.save(1 + '.pdf')
            })
        }
    }
}
</script>
<style scoped lang="scss">
.pdf-container{
    width: 100%;
    display: flex;
    flex-direction: column;
}
</style>
<style lang="scss">
#sidebar{
    display: none;
}
#page-container {
    position: inherit !important;
}
</style>